import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Question } from "./types";
import { fetchQuiz } from "src/api/sanity/queries";

export interface QuestionState {
  questions: Question[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null | undefined;
}

const initialState: QuestionState = {
  questions: [],
  status: "idle",
  error: null,
};

export const fetchQuestions = createAsyncThunk("/fetchQuestions", async () => {
  const response = await fetchQuiz();
  const [questionObj] = response || [];
  const { questions = [] } = questionObj;
  return questions;
});

const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    updateQuestions: (
      state: QuestionState,
      action: PayloadAction<Question[]>
    ) => {
      state.questions = [...action.payload];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuestions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Add  fetched questions to the array
        state.questions = state.questions.concat(action.payload);
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { updateQuestions } = questionSlice.actions;
export const selectError = (state: RootState) => state.questions.error;
export const selectQuestions = (state: RootState) => state.questions.questions;
export const selectStatus = (state: RootState) => state.questions.status;

export default questionSlice.reducer;
