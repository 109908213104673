import React from "react";
import { styled } from "../../styles/stitches.config";

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <Wrapper>
      <CustomBody>
        <span>Copyright {date}. All Rights Reserved by </span>
        <span>
          <a target="_blank" href="https://ciphernutz.com" rel="noreferrer">
            Ciphernutz
          </a>
        </span>
      </CustomBody>
    </Wrapper>
  );
};

const CustomBody = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 0px",
  fontSize: "14px",
  fontWeight: "$medium",
  "& a": {
    color: "#626262",
    textDecoration: "none",
    opacity: "0.7",
  },
  "& span": {
    "&:first-child": {
      opacity: "0.7",
      color: "#626262",
    },
    "&:nth-child(2)": {
      cursor: "pointer",
      marginLeft: "4px",
    },
  },
});

const Wrapper = styled("footer", {
  position: "relative",
  width: "100%",
});

export default Footer;
