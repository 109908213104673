import React, { InputHTMLAttributes } from "react";
import { styled } from "../../styles/stitches.config";

type Props = {
  label: string;
  required?: boolean;
  errors?: string;
  rows?: number;
} & InputHTMLAttributes<HTMLTextAreaElement>;

const Textarea: React.FC<Props> = (Props) => {
  const { label = false, ...rest } = Props;

  const renderLabel = () => {
    if (!label) return;
    return <Label>{label}</Label>;
  };
  return (
    <FormGroup>
      {renderLabel()}
      <StyledTextarea {...rest} />
    </FormGroup>
  );
};

const FormGroup = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const Label = styled("label", {
  fontWeight: "$medium",
});

const StyledTextarea = styled("textarea", {
  padding: "15px",
  margin: "10px 0 22px 0",
  display: "inline-block",
  border: "1px solid #ccc",
  borderRadius: "4px",
  cursor: "pointer",
  resize:"vertical",
});

export default Textarea;
