import React from "react";
import { styled } from "src/styles/stitches.config";
import { Question, Choice } from "../../types";

interface Props {
  handleSelectOption: (selectedOption: string) => void;
  selectedAnswer: string;
  presentQuestion?: Question;
}

const Answer: React.FC<Props> = ({
  handleSelectOption,
  selectedAnswer,
  presentQuestion,
}) => {
  const handleOptionSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSelectOption(e.target.value);
  };

  return (
    <Wrapper>
      {presentQuestion &&
        presentQuestion?.choices.map((answer: Choice, index: number) => (
          <OptionWrapper
            htmlFor={answer._key}
            key={index}
            isChecked={answer.title === selectedAnswer}
          >
            <Input
              type="radio"
              id={answer._key}
              name={answer._key}
              value={answer.title}
              checked={answer.title === selectedAnswer}
              onChange={handleOptionSelect}
            />
            <Options>{answer.title}</Options>
          </OptionWrapper>
        ))}
    </Wrapper>
  );
};

const OptionWrapper = styled("label", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  padding: "5px",
  margin: "8px",
  marginLeft: 0,
  border: "1px solid #ccc",
  cursor: "pointer",
  backgroundColor: "White",
  borderRadius: "8px",
  "&:hover": {
    border: "1px solid  #1E90FF",
    backgroundColor: "rgba(224, 240, 255, 1)",
  },
  variants: {
    isChecked: {
      true: {
        border: "1px solid  #1E90FF",
        backgroundColor: "rgba(224, 240, 255, 1)",
        borderRadius: "8px",
      },
    },
  },
});

const Wrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "@tablet": {
    display: "grid",
    columnGap: "10px",
    gridTemplateColumns: "1fr 1fr",
  },
});

const Input = styled("input", {
  opacity: 0,
  position: "fixed",
  width: 0,
  "& input[type=radio]:checked": {
    border: "1px solid  #D1E6F3",
    backgroundColor: "rgba(224, 240, 255, 1)",
  },
});
const Options = styled("p", {
  fontSize: "18px",
});

export default Answer;
