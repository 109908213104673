import React from "react";
import { styled } from "src/styles/stitches.config";
import { Question as QuestionType } from "../../types";

interface Props {
  currentQuestionIndex: number;
  presentQuestion?: QuestionType;
}

const Question = ({ currentQuestionIndex, presentQuestion }: Props) => {
  return (
    <QuestionWrapper>
      <Heading>
        Question {currentQuestionIndex + 1} of {10}
      </Heading>
      {presentQuestion && (
        <ParagraphWrapper>
          <Paragraph>{presentQuestion?.title}</Paragraph>
        </ParagraphWrapper>
      )}
    </QuestionWrapper>
  );
};

const Heading = styled("h2", {
  color: "White",
  background: "linear-gradient(90deg, #07CBE1 0%, #1883C2 100%)",
  padding: "10px 40px",
  borderRadius: "25px",
  position: "absolute",
  top: -35,
});

const ParagraphWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  width: "100%",
});

const Paragraph = styled("p", {
  border: "1px solid #ccc",
  padding: "50px 40px",
  borderRadius: "8px",
  marginTop: "8px",
  fontSize: "18px",
  lineHeight: "$4",
  color: "Black",
  backgroundColor: "White",
  "-webkit-user-select": "none",
  "-webkit-touch-callout": "none",
  "-moz-user-select": "none",
  "-ms-user-select": "none",
  "user-select": "none",
});

const QuestionWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  position: "relative",
});

export default Question;
