import { styled } from "../../styles/stitches.config";

const Wrapper = styled("div", {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100vh",
});

export default Wrapper;
