import React, { useEffect, useState } from "react";
import { styled } from "src/styles/stitches.config";
import { StyledButton } from "../Button";

interface Props {
  isModalVisible: boolean;
  onClose: () => void;
  handleCloseTest: () => void;
}

const Modal: React.FC<Props> = ({
  isModalVisible,
  onClose,
  handleCloseTest,
}) => {
  const [modalTimer, setModalTimer] = useState(10);

  useEffect(() => {
    if (modalTimer > 0) {
      const interval = setInterval(() => {
        setModalTimer((prev) => prev - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalTimer === 0) {
      handleCloseTest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalTimer]);

  const closeTest = () => {
    handleCloseTest();
  };
  return (
    <div>
      <ModalContainer>
        <ModalBox>
          <TitleButtonWrapper>
            <ModalCloseButton onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-2 h-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </ModalCloseButton>
            <ModalTitle>Are you sure you want to quit?</ModalTitle>
          </TitleButtonWrapper>
          <ModalContent>
            {" "}
            We noticed that you moved out of the window. Your test will end and
            your saved progress will be lost if you do it again.
          </ModalContent>
          <StyledText>Time Remaining: {modalTimer} sec</StyledText>
          <ModalButtonWrapper>
            <ModalLeaveButton onClick={closeTest}>Leave</ModalLeaveButton>
            <ModalContinueButton onClick={onClose}>
              Continue
            </ModalContinueButton>
          </ModalButtonWrapper>
        </ModalBox>
      </ModalContainer>
    </div>
  );
};

const ModalContainer = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.3)",

  zIndex: 1000,
  width: "100%",
  height: "100%",
  position: "absolute",
  inset: 0,
  display: "flex",
  overflow: "auto",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "auto",
  transition: "0.4s",
});

const ModalLeaveButton = styled(StyledButton, {
  background: "red !important",
  opacity: 0.5,
  padding:"10px"
});

const ModalContinueButton = styled(StyledButton, {
  background: "green !important",
  opacity: 0.7,
  padding:"10px"
});
const ModalButtonWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "20px",
  padding: "30px",
  color:"white"
});

const TitleButtonWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
});

const ModalCloseButton = styled("div", {
  color: "#222224",
  fontSize: "20px",
  fontWeight: 300,
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  top: "20px",
  right: "20px",
  position: "absolute",
  cursor: "pointer",
  "@tablet": {
    width: "36px",
    height: "36px",
  },
});

const ModalTitle = styled("h2", {
  margin: 0,
  padding: "48px 32px 0 32px",
  fontWeight: 500,
  fontSize: "22px",
  lineHeight: "$2",
  display: "flex",
  alignItems: "center",
  "@tablet": {
    fontSize: "26px",
    lineHeight: "34px",
  },
});

const ModalContent = styled("div", {
  padding: "32px",
  fontSize: "15px",
  lineHeight: "1.25rem",
  "@tablet": {
    fontSize: "18px",
    lineHeight: "1.5rem",
  },
});

const ModalBox = styled("div", {
  backgroundColor: "White",
  maxWidth: "520px",
  margin: "auto 20px",
  borderRadius: "24px",
  position: "relative",
});

const StyledText = styled("div", {
  color: "#c55a36",
  opacity: "0.8",
  display: "flex",
  justifyContent: "center",
  fontSize: "$2",
  lineHeight: "$1",
});

export default Modal;
