import React from "react";
import { Link } from "react-router-dom";
import { styled } from "src/styles/stitches.config";
import { H3 } from "../Typography";

const BackLink = () => {
  return (
      <Text>
        Go back to <StyledLink to="/">Home</StyledLink> page
      </Text>
  );
};

const StyledLink = styled(Link, {
  textDecoration: "none",
});

const Text = styled(H3, {
  textAlign: "center",
  opacity: 0.7,
  "& span": {
    color: "blue",
  },
});


export default BackLink;
