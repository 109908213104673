const Airtable = require("airtable");

// Authenticate
Airtable.configure({
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

// Initialize a base
const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);

// Reference a table
const table = base(process.env.REACT_APP_AIRTABLE_TABLE_NAME);

const minifyItems = (records) =>
  records.map((record) => getMinifiedItem(record));

const getMinifiedItem = (record) => {
  return {
    id: record.id,
    fields: record.fields,
  };
};

export const createUser = async (values) => {
  try {
    const {
      firstName,
      lastName,
      designation,
      currentCompany,
      email,
      phone,
      experience,
    } = values;
    const res = await table.create([
      {
        fields: {
          first_name: firstName,
          last_name: lastName,
          designation,
          current_company: currentCompany,
          email,
          number: phone,
          experience,
          scores: 0,
          total: 10,
        },
      },
    ]);

    const newUser = await getMinifiedItem(res[0]);
    return newUser;
  } catch (error) {
    return error;
  }
};

export const updateItem = async (updatedItem) => {
  try {
    const { id, fields } = updatedItem;
    const updatedRecords = await table.update([{ id, fields }]);
    const updatedUser = await getMinifiedItem(updatedRecords[0]);
    return updatedUser;
  } catch (error) {
    return error;
  }
};

export default async function getUsers() {
  try {
    const records = await table.select({}).all();
    const allUsers = minifyItems(records);
    return allUsers;
  } catch (error) {
    return error;
  }
}
