import Register from "src/pages/Register";
import Instructions from "./pages/Instructions";
import Quiz from "./pages/Quiz";
import { Result } from "src/pages/Result";
import CandidateDetailsForm from "./pages/CandidateDetailsForm";

const routes = [
  {
    path: "/",
    name: "Register",
    component: Register,
    layout: "public",
  },
  {
    path: "/instructions",
    name: "Instructions",
    component: Instructions,
    layout: "public",
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: Quiz,
    layout: "private",
  },
  {
    path: "/form",
    name: "From",
    component: CandidateDetailsForm,
    layout: "private",
  },
  {
    path: "/exit",
    name: "Exit",
    component: Result,
    layout: "public",
  },
];

export default routes;
