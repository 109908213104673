import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { styled } from "src/styles/stitches.config";

interface Props {
  setIsTimeOut: Dispatch<SetStateAction<boolean>>;
  pauseTimer: boolean;
}

const Timer = ({ setIsTimeOut, pauseTimer }: Props) => {
  const [timer, setTimer] = useState(900);

  useEffect(() => {
    if (timer === 0) return setIsTimeOut(true);
    if (!pauseTimer) {
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 0) {
            clearInterval(interval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer, setIsTimeOut, pauseTimer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <>
      <TimeWrapper>
        <Clock
          xmlns="http://www.w3.org/2000/Clock"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinejoin="round"
            strokeLinecap="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </Clock>

        <StyledTime>{formattedTime}</StyledTime>
      </TimeWrapper>
    </>
  );
};

const Clock = styled("svg", {
  width: "30px",
  height: "30px",
  color: "#c55a36",
  marginRight: "10px",
});

const TimeWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@desktop": { marginBottom: "25px" },
});

const StyledTime = styled("h2", {
  textAlign: "center",
  color: "#c55a36",
});

export default Timer;
