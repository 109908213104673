import { styled } from "../../styles/stitches.config";

const Container = styled("div", {
  maxWidth: "768px",
  background: "White",

  "@tablet": {
    maxWidth: "700px",
    margin: "60px auto",
    border: "1px solid #ddd",
    borderRadius: "5px",
    boxShadow: "1px 1px 15px -9px rgb(202 202 202 / 38%)",
  },

  "@desktop": {
    width: "800px",
  },
});

export default Container;
