import type * as stitches from "@stitches/react";
import { createStitches } from "@stitches/react";

const blue = {
  blue100: "#12DAE9",
  blue200: "#1883C2",
  blue300: "#13304C",
  blue400: "#4267b2",
  blue500: "#0A66C2",
};

const white = {
  white100: "#FFFFFF",
  white200: "#F1F8F9",
  white300: "#F6F7F8",
  white400: "#EBEEF0",
  white500: "#EEF0F2",
};

const lightBackground = {
  lightBlue: "rgba(224, 240, 255, 1)",
  lightGreen: "rgba(18, 218, 233, 0.1)",
  darkSkyBlue: "#D1E6F3",
  skyBlue: "#1DA1F2",
  lightGray: "#FAFAFA",
};

const gradient = {
  blueGradientLeftToRight: "linear-gradient(90deg, #07CBE1 0%, #1883C2 100%)",
  blueGradientTopToBottom: "linear-gradient(180deg, #07CBE1 0%, #1883C2 100%)",
  blueGradientRightToLeft: "linear-gradient(-90deg, #07CBE1 0%, #1883C2 100%)",
};

const black = {
  black100: "#171515",
  black: "#0C0C0C",
};
const orange = {
  orange: "#DFA580",
  orange100: "#FE6868",
};

export const { styled, css, theme, config, globalCss, keyframes } =
  createStitches({
    theme: {
      colors: {
        ...blue,
        ...white,
        ...gradient,
        ...lightBackground,
        ...black,
        ...orange,
        lightBtnText: "rgba(19, 48, 76, 0.7)",
        brown: "#B39E91",
        gray: "#DADADA",
        green: "#008060",
        error: "#ff0000",
      },
      fonts: {
        c: "Arial,sans-serif",
        times: "Times",
        unica: "Unica",
      },
      fontWeights: {
        thin: "100",
        light: "300",
        medium: "400",
        large: "500",
        semibold: "600",
        bold: "800",
      },
      fontSizes: {
        1: "1rem",
        2: "1.125rem",
        3: "1.25rem",
        4: "1.5rem",
        5: "1.75rem",
        6: "2rem",
      },
      lineHeights: {
        1: "1.25rem",
        2: "1.5rem",
        3: "1.75rem",
        4: "2rem",
        5: "2.25rem",
      },
    },
    media: {
      mobile: "(min-width: 320px)",
      tablet: "(min-width: 768px)",
      desktop: "(min-width: 1024px)",
      desktopLg: "(min-width: 1440px)",
    },
  });

export type CSS = stitches.CSS<typeof config>;
