import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "./types";

export interface UserState {
  user: User;
  isSession: boolean;
}

const initialState: UserState = {
  user: {} as User,
  isSession: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signIn: (state: UserState, action: PayloadAction<User>) => {
      state.user = { ...action.payload };
      state.isSession = true;
    },
    signOut: (state: UserState) => {
      state.user = {} as User;
      state.isSession = false;
    },
  },
});

export const { signIn, signOut } = userSlice.actions;
export const selectSession = (state: RootState) => state.user.isSession;
export const selectUser = (state: RootState) => state.user.user;
export default userSlice.reducer;
