import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router";
import PrivateLayout from "./layout/PrivateLayout";
import PublicLayout from "./layout/PublicLayout";
import Register from "./pages/Register";
import { selectSession } from "./redux/slices/userSlice";
import RoutesList from "./routes";

function App() {
  const { pathname } = useLocation();

  const isSession = useSelector(selectSession);
  const renderRoutes = () => {
    const renderRoute = (Component: React.FC, layout: string) => {
      if (Component) {
        switch (layout) {
          case "private":
            return !isSession && !pathname.includes("exit") ? (
              <Navigate to="/" replace />
            ) : (
              <PrivateLayout>
                <Component />
              </PrivateLayout>
            );
          case "public":
            return (
              <PublicLayout>
                <Component />
              </PublicLayout>
            );
          default:
            return (
              <PublicLayout>
                <Register />
              </PublicLayout>
            );
        }
      }
      return null;
    };
    return RoutesList.map((route) => (
      <Route
        key={route.name}
        path={route.path}
        element={renderRoute(route.component, route.layout)}
      />
    ));
  };
  return <Routes>{renderRoutes()}</Routes>;
}

export default App;
