import React, { useEffect, useState } from "react";
import { AppDispatch } from "src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../components/Container";
import Question from "./components/Question";
import Answer from "./components/Answer";
import Timer from "./components/Timer";
import Loader from "src/components/Loader";
import { Question as QuestionType } from "./types";
import { styled } from "src/styles/stitches.config";
import {
  fetchQuestions,
  selectQuestions,
  selectStatus,
} from "src/redux/slices/questionSlice";
import { selectUser, signOut } from "src/redux/slices/userSlice";
import { updateItem } from "src/api/airtable";
import { useNavigate } from "react-router";
import Modal from "src/components/Modal";
import { H2 } from "src/components/Typography";
import { BaseButton } from "../../components/Button";

const Quiz = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUser);
  const questionsStatus = useSelector(selectStatus);
  const questions = useSelector(selectQuestions);
  const [warningCounter, setWarningCounter] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [endTest, setEndTest] = useState<boolean>(false);
  const [isTimeOut, setIsTimeOut] = useState<boolean>(false);
  const [presentQuestion, setPresentQuestion] = useState<QuestionType>();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalLeave, setIsModalLeave] = useState(false);
  const [pauseTimer, setPauseTimer] = useState(false);
  const [randomQuestion, setRandomQuestion] = useState<any[]>([]);

  useEffect(() => {
    if (questions.length) {
      const shuffledQuestions = [...questions]
        .map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value)
        .slice(0, 10);

      setRandomQuestion(shuffledQuestions);
    }
  }, [questions]);

  useEffect(() => {
    if (questionsStatus === "idle") {
      dispatch(fetchQuestions());
    }
    if (questionsStatus === "succeeded" && randomQuestion.length) {
      handleShuffleQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsStatus, currentQuestionIndex, randomQuestion]);

  useEffect(() => {
    //if a user hovers over refresh
    document.onmouseleave = handleMouseOver;
    //for going to previous page
    window.addEventListener("popstate", () => {
      let message = window.alert(
        "Due to violating examination guidelines, Quiz has ended for you.",
      );
      if (message === undefined) {
        dispatch(signOut());
        setEndTest(true);
      }
    });
    //for refresh
    window.addEventListener("beforeunload", handleReload);
    //for tab switching and program switching
    window.addEventListener("visibilitychange", handleVisibility);
    window.addEventListener("blur", handleTabSwitching);

    return () => {
      window.removeEventListener("beforeunload", handleReload);
      window.removeEventListener("visibilitychange", handleVisibility);
      window.removeEventListener("popstate", handleBackRouting);
      window.removeEventListener("blur", handleTabSwitching);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleShuffleQuestions();
    //if counter is interrupted
    if (isTimeOut) {
      submitQuiz();
    }

    //it ends the test
    if (isModalLeave) {
      setEndTest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeOut, isModalLeave]);

  useEffect(() => {
    if (endTest) {
      navigate("/exit");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTest]);

  useEffect(() => {
    //if modal is Visible keep the count and if not Visible continue the time to resume from where it stopped
    isModalVisible
      ? setWarningCounter((prev) => prev + 1)
      : setPauseTimer(false);
    //if user switches tab or hovers over more than thrice then test will end
    if (warningCounter === 3) {
      setEndTest(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const handleBackRouting = () => {
    let message = window.alert(
      "Due to violating examination guidelines, Quiz has ended for you.",
    );
    if (message === undefined) {
      dispatch(signOut());
      setEndTest(true);
    }
  };
  const handleVisibility = (e: any) => {
    if (document.hidden) {
      setIsModalVisible(true);
    }
  };

  const handleTabSwitching = () => {
    setIsModalVisible(true);
  };
  const handleShuffleQuestions = () => {
    const currentQuestion = randomQuestion[currentQuestionIndex];
    setPresentQuestion(currentQuestion);
  };

  const handleMouseOver = () => {
    setIsModalVisible(true);
    setPauseTimer(true);
  };

  const handleReload = (e: any) => {
    setIsModalVisible(true);
    e.preventDefault();
    e.returnValue = " ";
  };

  const handleSelectOption = (selectedOption: string) => {
    setRandomQuestion((prevRandomQuestion) => {
      const updatedQuestions = [...prevRandomQuestion];
      updatedQuestions[currentQuestionIndex] = {
        ...updatedQuestions[currentQuestionIndex],
        selectedOption,
      };
      return updatedQuestions;
    });
  };
  const handleNext = async () => {
    const nextQues = currentQuestionIndex + 1;
    setIsTimeOut(false);
    nextQues < 10 && setCurrentQuestionIndex(nextQues);
    if (nextQues === 10) {
      await submitQuiz();
    }
  };
  const submitQuiz = async () => {
    let total = 0;
    randomQuestion?.forEach((question) => {
      const correctAns = question?.choices.find((data: any) => data.isCorrect);
      if (correctAns?.title === question?.selectedOption) {
        total = total + 1;
      }
    });
    const updatedFields = {
      scores: total,
    };
    const updatedItem = { id: user.id, fields: updatedFields };
    const updatedUser = await updateItem(updatedItem);

    if (updatedUser) {
      setEndTest(true);
    }
  };

  const handlePrev = async () => {
    setCurrentQuestionIndex((prevQues) => Math.max(prevQues - 1, 0));
  };

  if (questionsStatus === "loading" && !randomQuestion.length) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <H2>Quiz</H2>
        </HeaderWrapper>
        <QuizWrapper>
          <Timer setIsTimeOut={setIsTimeOut} pauseTimer={pauseTimer} />
          <Question
            presentQuestion={presentQuestion}
            currentQuestionIndex={currentQuestionIndex}
          />
          <Answer
            presentQuestion={presentQuestion}
            selectedAnswer={
              randomQuestion[currentQuestionIndex]?.selectedOption || ""
            }
            handleSelectOption={handleSelectOption}
          />
          <ButtonWrapper>
            <StyledButton
              onClick={handlePrev}
              disabled={currentQuestionIndex === 0}
              variant="btnGradientSolid"
            >
              <StyledSpan>Previous</StyledSpan>
            </StyledButton>

            <StyledButton onClick={handleNext} variant="btnGradientSolid">
              <StyledSpan>
                {currentQuestionIndex + 1 === 10 ? "Submit" : "Next"}
              </StyledSpan>
            </StyledButton>
          </ButtonWrapper>
          {isModalVisible && (
            <Modal
              isModalVisible={isModalVisible}
              handleCloseTest={() => setIsModalLeave(true)}
              onClose={() => setIsModalVisible(false)}
            />
          )}
        </QuizWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Container, {
  "@Desktop": {
    backgroundColor: "AliceBlue",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 100px",
  },
});
const HeaderWrapper = styled("div", {
  borderBottom: " 1px solid #f1f1f1",
  boxShadow: " 0px 15px 10px -20px #888",
});

const QuizWrapper = styled("div", {
  padding: "7px 36px 38px",
});

const ButtonWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 20,
});
export const StyledButton = styled(BaseButton, {
  maxWidth: "250px",
  width: "100%",
  variants: {
    variant: {
      btnGradientSolid: {
        color: "$white100",
        fontSize: "$2",
        lineHeight: "20px",
        fontFamily: "$medium",
        padding: "16px 24px",
        background: "$blueGradientLeftToRight",
        borderRadius: "8px",
      },
    },
  },
});
const LoaderWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 137px)",
});
const StyledSpan = styled("span", {
  fontWeight: "$semibold",
});

export default Quiz;
