import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Input from "src/components/Input";
import { Form, FormikProps, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { styled } from "src/styles/stitches.config";
import getUsers, { createUser } from "src/api/airtable";
import { User } from "./types";
import { useNavigate } from "react-router";
import Loader from "src/components/Loader";
import { useDispatch } from "react-redux";
import { signIn } from "src/redux/slices/userSlice";
import { AppDispatch } from "src/redux/store";
import { H2 } from "src/components/Typography";

interface SigninValues {
  firstName: string;
  lastName: string;
  email: string;
  designation: string;
  phone: string;
  currentCompany: string;
  experience: number;
}

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const allUsers = await getUsers();
    setUsers(allUsers);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("First Name is required"),
    lastName: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    designation: Yup.string()
      .max(20, "Must be 20 characters or less")
      .required("Designation is required"),
    currentCompany: Yup.string(),
    experience: Yup.number()
      .min(0, "Experience must be greater than 0")
      .max(20, "Invalid")
      .required("Experience is required"),
    phone: Yup.string()
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        "Mobile number is not valid"
      )
      .max(10, "Mobile number must be 10 digits")
      .min(10, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
  });

  const initialValues: SigninValues = {
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    phone: "",
    currentCompany: "",
    experience: 0,
  };

  const handleDuplicateUser = (values: SigninValues) => {
    let isExistingEmail = users.some(
      (user) => user.fields.email === values.email
    );
    return isExistingEmail;
  };

  const handleSubmit = async (
    values: SigninValues,
    formikHelpers: FormikHelpers<SigninValues>
  ) => {
    setLoading(true);
    const isExistingUser = handleDuplicateUser(values);
    if (!isExistingUser) {
      const newUser = (await createUser(values)) as User;
      const fields = newUser?.fields;
      const id: string = newUser?.id;
      dispatch(signIn({ id, fields }));
      navigate("/form");
    } else {
      setLoading(false);
      toast.error("You have already attempted the test");
      formikHelpers.resetForm();
    }
  };

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <Container>
            <HeaderWrapper>
              <H2>Register</H2>
            </HeaderWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, formikHelpers) =>
                handleSubmit(values, formikHelpers)
              }
            >
              {({
                values,
                touched,
                errors,
                isValid,
                dirty,
                handleBlur,
                handleChange,
              }: FormikProps<SigninValues>) => {
                return (
                  <FormWrapper>
                    <Form>
                      <LoginWrapper>
                        <Input
                          label="First Name"
                          value={values.firstName}
                          placeholder="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="firstName"
                          touched={touched.firstName}
                          errors={errors.firstName}
                          required
                        />
                        <Input
                          label="Last Name"
                          value={values.lastName}
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="lastName"
                          touched={touched.lastName}
                          errors={errors.lastName}
                          required
                        />
                        <Input
                          label="Email"
                          value={values.email}
                          placeholder="Email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="email"
                          touched={touched.email}
                          errors={errors.email}
                          required
                        />
                        <Input
                          label="Designation"
                          value={values.designation}
                          placeholder="Designation"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="designation"
                          touched={touched.designation}
                          errors={errors.designation}
                          required
                        />
                        <Input
                          label="Mobile Number"
                          value={values.phone}
                          placeholder="Mobile Number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="phone"
                          touched={touched.phone}
                          errors={errors.phone}
                          required
                        />
                        <Input
                          label="Current Company"
                          value={values.currentCompany}
                          placeholder="Current Company"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="currentCompany"
                          touched={touched.currentCompany}
                          errors={errors.currentCompany}
                        />
                        <Input
                          label="Experience (in years)"
                          min="0"
                          value={values.experience}
                          placeholder="Experience (in years)"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="experience"
                          type="number"
                          touched={touched.experience}
                          errors={errors.experience}
                          required
                        />
                      </LoginWrapper>
                      <ButtonWrapper>
                        <Button
                          disabled={!isValid || !dirty}
                          title="Submit"
                        />
                      </ButtonWrapper>
                    </Form>
                  </FormWrapper>
                );
              }}
            </Formik>
          </Container>
        </>
      )}
    </>
  );
};

const LoginWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  rowGap: "10px",

  "@tablet": {
    gridTemplateColumns: "1fr 1fr",
    columnGap: "50px",
    rowGap: "10px",
  },
});

const ButtonWrapper = styled("div", {
  margin: "8px auto 0",
  "@tablet": {
    width: "200px",
  },
});

const HeaderWrapper = styled("div", {
  borderBottom: " 1px solid #f1f1f1",
  boxShadow: " 0px 15px 10px -20px #888",
});
const LoaderWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height:'calc(100vh - 137px)',
});

const FormWrapper = styled("div", {
  padding: "36px",
});

export default Register;
