import React from "react";
import { styled } from "src/styles/stitches.config";
import Button from "../../components/Button";
import Container from "../../components/Container";
import { H2 } from "src/components/Typography";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectSession } from "src/redux/slices/userSlice";
import BackLink from "src/components/BackLink";

const instructionsData = [
  "Topic of quiz is logical reasoning which consists of 10 questions.",
  "Test will end automatically and your saved progress will be lost if you switch tab or refresh the page.",
  "You have 15 minutes to finish this quiz.",
  "The test will save and submit automatically when the time expires.",
];

const Instructions = () => {
  const session = useSelector(selectSession);

  const navigate = useNavigate();

  const handleStart = () => {
    navigate("/quiz");
  };
  return (
    <>
      <Container>
        <HeaderWrapper>
          <H2>Instructions for test</H2>
        </HeaderWrapper>
        <InstructionsInfoContainer>
          <Lists>
            {instructionsData.map((data, i) => {
              return <List key={i}>{data}</List>;
            })}
          </Lists>
          <ButtonWrapper>
            <Button
              title="Start Test"
              onClick={handleStart}
              disabled={!session}
            />
          </ButtonWrapper>
          {!session && <BackLink />}
        </InstructionsInfoContainer>
      </Container>
    </>
  );
};

const HeaderWrapper = styled("div", {
  borderBottom: " 1px solid #f1f1f1",
  boxShadow: " 0px 15px 10px -20px #888",
});

const InstructionsInfoContainer = styled("div", {
  padding: "7px 48px 40px",
});

const Lists = styled("ul", {
  opacity: "0.7",
  margin: " 0px;",
  padding: "0px",
});

const List = styled("li", {
  listStylePosition: "inside",
  fontSize: "$1",
  textIndent: "-25px",
  paddingLeft: "21px",
  marginTop: "30px",
  color: "$black",
  lineHeight: "$3",
  "@tablet": {
    marginTop: "10px",
    fontSize: "$2",
    lineHeight: "$4",
    textIndent: "-30px",
  },
});

const ButtonWrapper = styled("div", {
  "@tablet": {
    width: "200px",
    margin: "30px auto 0",
  },
});

export default Instructions;
