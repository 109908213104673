import React from "react";
import Footer from "src/components/Footer";
import { Header } from "src/components/Header";
import Wrapper from "src/components/Wrapper";

interface Props {
  children: React.ReactNode;
}

const PublicLayout: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <div>
        <Header />
        {children}
      </div>
      <Footer />
    </Wrapper>
  );
};

export default PublicLayout;
