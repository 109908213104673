import React from "react";
import { styled } from "../../styles/stitches.config";
interface Props {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ title, onClick, disabled }) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      variant="btnGradientSolid"
      width="full"
    >
      <StyledSpan>{title}</StyledSpan>
    </StyledButton>
  );
};

export const BaseButton = styled("button", {
  all: "unset",
  alignItems: "center",
  boxSizing: "border-box",
  userSelect: "none",
  display: "inline-flex",
  flexShrink: 0,
  justifyContent: "center",
  lineHeight: "1",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  cursor: "pointer",
  "&:disabled": {
    cursor: "not-allowed",
    opacity: 0.5,
  }
});

export const StyledButton = styled(BaseButton, {
  variants: {
    width: {
      full: {
        width: "100%",
      },
      fit: {
        width: "fit-content",
      },
    },
    variant: {
      btnGradientSolid: {
        color: "$white100",
        fontSize: "$2",
        lineHeight: "20px",
        fontFamily: "$medium",
        padding: "16px 24px",
        background: "$blueGradientLeftToRight",
        borderRadius: "8px",
      },
    },
  },
});

const StyledSpan = styled("span", {
  fontWeight: "$semibold",
});

export default Button;
