import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BackLink from "src/components/BackLink";
import { signOut } from "src/redux/slices/userSlice";
import { styled } from "src/styles/stitches.config";
import Container from "src/components/Container";

export const Result = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Container>
        <H1>Your Quiz has ended. Thank you for your response!</H1>
      <BackLink />  
      </Container>
    </>
  );
};

const H1 = styled("h1", {
  fontSize: "$4",
  lineHeight: "$5",
  fontWeight: "$semibold",
  textAlign: "center",
});
