import React, { useState, ChangeEvent, FormEvent } from "react";
import Button from "../../components/Button";
import Container from "../../components/Container";
import { styled } from "src/styles/stitches.config";
import Textarea from "src/components/Textarea";
import { H2 } from "src/components/Typography";
import { useSelector } from "react-redux";
import { selectSession, selectUser } from "src/redux/slices/userSlice";
import { updateItem } from "src/api/airtable";
import { useNavigate } from "react-router";
import BackLink from "src/components/BackLink";
import Input from "src/components/Input";

const CandidateDetailsForm = () => {
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const user = useSelector(selectUser);
  const questions = {
    introduction: "",
    skills: "",
    current_salary: "",
    expected_salary: "",
    what_add_to_company_culture: "",
    question_for_us: "",
  };
  const [formData, setFormData] = useState({
    ...questions,
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const updatedItem = { id: user.id, fields: formData };
    const updatedUser = await updateItem(updatedItem);
    if (updatedUser) {
      navigate("/instructions");
    }
    //submit api
    setFormData({ ...questions });
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <H2>Tell us about your self</H2>
      </HeaderWrapper>
      <FormWrapper>
        <form onSubmit={handleSubmit}>
          <QuestionWrapper>
            <TextareaWrapper
              label="A brief introduction about yourself."
              value={formData.introduction}
              placeholder="Answer"
              onChange={handleChange}
              name="introduction"
            />
            <Textarea
              label="What are the major skills regarding the job you have applied for ?"
              value={formData.skills}
              placeholder="Answer"
              onChange={handleChange}
              name="skills"
            />
            <Textarea
              label="What will you add to the company culture ?"
              value={formData.what_add_to_company_culture}
              placeholder="Answer"
              onChange={handleChange}
              name="what_add_to_company_culture"
            />
            <Textarea
              label="Do you have any questions for us ?"
              value={formData.question_for_us}
              placeholder="Answer"
              onChange={handleChange}
              name="question_for_us"
            />
            <Input
              label="What is your current salary (if any)?"
              value={formData.current_salary}
              placeholder="Answer"
              onChange={handleChange}
              name="current_salary"
            />
            <Input
              label="What is your expected salary?"
              value={formData.expected_salary}
              placeholder="Answer"
              onChange={handleChange}
              name="expected_salary"
            />
          </QuestionWrapper>
          <ButtonWrapper>
            <Button title="Submit" disabled={!session} />
          </ButtonWrapper>
          {!session && <BackLink />}
        </form>
      </FormWrapper>
    </Wrapper>
  );
};
const Wrapper = styled(Container, {
  "@desktop": {
    width: "992px",
    maxWidth: "1004px",
  },
  "@desktopLg": {
    width: "1140px",
    maxWidth: "1140px",
  },
});

const QuestionWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "1fr",
  rowGap: "10px",

  "@tablet": {
    gridTemplateColumns: "1fr 1fr",
    columnGap: "50px",
    rowGap: "10px",
  },
});

const ButtonWrapper = styled("div", {
  margin: "8px auto 0",
  "@tablet": {
    width: "200px",
  },
});

const HeaderWrapper = styled("div", {
  borderBottom: " 1px solid #f1f1f1",
  boxShadow: " 0px 15px 10px -20px #888",
});

const TextareaWrapper = styled(Textarea, {
  maxHeight: "250px",
});

const FormWrapper = styled("div", {
  padding: "36px",
});

export default CandidateDetailsForm;
