import { client } from "./index";

export const fetchQuiz = async () => {
  const query = `*[_type == "quiz"]{
  
    questions,description
    }
      `;
  const quiz = await client.fetch(query);

  return quiz;
};
