import { styled } from "../../styles/stitches.config";

const fontFamilyVariants = {
  variants: {
    textSize: {
      sm: {
        fontSize: "$1",
        lineHeight: "12px",
      },
    },
    bodySize: {
      lg: {
        fontSize: "$6",
        lineHeight: "28px",
      },
      md: {
        fontSize: "$4",
        lineHeight: "24px",
      },
      sm: {
        fontSize: "$3",
        lineHeight: "20px",
      },
    },
    color: {
      white: {
        color: "$white100",
      },
      black: {
        color: "$black",
      },
    },
    fontWeight: {
      light: {
        fontFamily: "$light",
      },
      medium: {
        fontFamily: "$medium",
      },
      bold: {
        fontFamily: "$bold",
      },
      black: {
        fontFamily: "$black",
      },
    },
  },
};

export const H1 = styled("h1", {
  fontSize: "$12",
  lineHeight: "72px",
  fontFamily: "$black",
  "em, a": {
    fontStyle: "$normal",
  },
  ...fontFamilyVariants,
});

export const H2 = styled("h2", {
  marginTop: "16px",
  fontSize: "$4",
  lineHeight: "$4",
  textAlign: "center",
  "@tablet": {
    fontSize: "$5",
    lineHeight: "$5",
  },

  "em, a": {
    fontStyle: "normal",
  },
  ...fontFamilyVariants,
});

export const H3 = styled("h3", {
  fontSize: "$7",
  fontFamily: "$medium",
  lineHeight: "32px",
  "em, a": {
    fontStyle: "normal",
  },
  ...fontFamilyVariants,
});

export const H4 = styled("h4", {
  fontSize: "$4",
  lineHeight: "$4",
  fontWeight: "$semibold",
  textAlign: "center",

  "em, a": {
    fontStyle: "normal",
  },
  ...fontFamilyVariants,
});

export const H5 = styled("h5", {
  fontSize: "$4",
  fontFamily: "$medium",
  lineHeight: "24px",
  "em, a": {
    fontStyle: "normal",
  },
  ...fontFamilyVariants,
});

export const H6 = styled("h6", {
  fontSize: "$3",
  fontFamily: "$medium",
  lineHeight: "22px",
  "em, a": {
    fontStyle: "normal",
  },
  ...fontFamilyVariants,
});

export const Body = styled("p", {
  fontSize: "$6",
  fontFamily: "$medium",
  lineHeight: "28px",
  "em, a": {
    fontStyle: "normal",
  },
  ...fontFamilyVariants,
});

export const Text = styled("p", {
  fontSize: "$2",
  fontFamily: "$bold",
  lineHeight: "16px",
  ...fontFamilyVariants,
});
