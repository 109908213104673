import React, { InputHTMLAttributes } from "react";
import { styled } from "../../styles/stitches.config";

type Props = {
  label: string;
  touched?: boolean;
  required?: boolean;
  errors?: string;
} & InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = (Props) => {
  const {
    label,
    touched = false,
    required = false,
    errors = "",
    ...rest
  } = Props;

  const renderLabel = () => {
    if (!label) return;
    return (
      <Label>
        {label}
        {required && <Span>*</Span>}
      </Label>
    );
  };
  return (
    <FormGroup>
      {renderLabel()}
      <StyledInput
        {...rest}
        error={!!errors && touched}
        autoComplete="off"
      />
      {touched && errors && <Errors>{errors}</Errors>}
    </FormGroup>
  );
};

const FormGroup = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const Label = styled("label", {
  fontWeight: "$medium",
});

const Span = styled("span", {
  color: "red",
  marginLeft: "4px",
});

const Errors = styled("div", {
  color: "red",
  marginTop: "-14px",
  fontSize: "14px",
});

const StyledInput = styled("input", {
  padding: "15px",
  margin: "10px 0 22px 0",
  display: "inline-block",
  border: "1px solid #ccc",
  transition: "all 0.3s ease",
  borderRadius: "4px",
  cursor: "pointer",
  variants: {
    error: {
      true: {
        border: "1px solid red",
      },
    },
  },
  "&:focus": {
    border: "1px solid #719ECE",
    outline: "none",
  },
});

export default Input;
