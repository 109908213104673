import LogoWhite from "src/assets/images/logo.svg";
import { styled } from "../../styles/stitches.config";

export const Header = () => {
  return (
    <HeaderWrapper>
        <LogoWrapper>
          <a target="_blank" href="https://ciphernutz.com" rel="noreferrer">
            <img alt="logo" src={LogoWhite} width={200} />
          </a>
        </LogoWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled("div", {
  background: " #fff",
  paddingTop: "10px",
  borderBottom: " 1px solid #f1f1f1",
  boxShadow: " 0px 15px 10px -20px #888",
  minHeight: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  transition: "box-shadow  ease-in-out",

  variants: {
    scrolling: {
      true: {
        position: "sticky",
        top: 0,
        left: 0,
        width: "100%",
        boxShadow: "rgb(60 48 48 / 38%) 3px 3px 21px -9px)",
      },
    },
  },
});


const LogoWrapper = styled("div", {
  minHeight: "80px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  border: "none",
  zIndex: 10,
  transition: "box-shadow  ease-in-out",
});

